.mainContainerpack {
  border: 1px solid #fff;
  margin-left: 1%;
  margin-right: 1%;
  font-family: "Honeywell Sans";
}

.packaging-heading {
  top: 674px;
  left: 228px;
  width: 695px;
  height: 141px;
}

.main2 h1 {
  text-align: left;
  /* padding: 10px; */
  font-size: 2.5vw;
  letter-spacing: 0px;
  color: #fff;
  line-height: 1;
}
.main2 h2 {
  line-height: 1;
}

/* Style the video: 100% width and height to cover the entire window */
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

/* Add some content at the bottom of the video/page */
.main2 {
  position: relative;
  bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  color: #fff;
  width: 100%;
  padding-bottom: 10px;
  z-index: 999;
}

.pack_gif svg {
  fill: #fff;
  width: 200px;
  height: 310px;
}

@media only screen and (min-width:820px) {

  .video1:before {
    height: 32.4% !important;
  }

}

@media only screen and (max-width: 576px) and (max-width: 786px)and (max-width: 768px)and (max-width: 1024px) and (max-width:1180px) {
  .mainContainerpack {
    padding-bottom: 20px !important;

  }

  .main2 {
    /* padding-bottom: 640px; */
    padding-left: 5px !important;
    padding-right: 0px !important;

  }

  .main2 h1,.main2 h2 {
    font-size: 15px !important;
    line-height: 1 !important;
    font-weight: bold !important;
  }
  
  .main2 h4 {
    font-size: 9px !important;
    line-height: 1.5 !important;
    padding-top: 5px !important;
  }

  .pack_gif svg {
    width: 145px;
    padding-bottom: 8%;
    height: 100px;

  }

  .pack_gif {
    padding-top: 85px !important;
  }

}

@media only screen and (min-width:768px) {

  .main2 {
    color: #fff;
  }

  .pack_gif svg {
    filter: none !important;
  }

  .video1:before {
    height: 350px !important;
  }

}

@media only screen and (min-width:912px) {
  .video1:before {
    height: 355px !important;
  }
}

/* Style the button used to pause/play the video */
#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}

#background-video2 {
  width: 98%;
  height: 320px;
  position: fixed;
  object-fit: cover;
  filter: brightness(40%);
}

.table>:not(caption)>*>* {
  padding: 0rem 0rem 0rem 1rem !important;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
}

.bck2::after {
  background: linear-gradient(to left, rgba(0, 0, 0, 0) 20%, rgba(0, 0, 0, 1)), url();
  z-index: 200;
  height: 100%;
}

.result-tbody {
  background-color: #ddddde !important;
}

.main-packaging {
  padding-bottom: 0px;
}


.content {
  position: relative;
  /* width: 500px; */
  /* margin: 0 auto; */
  /* padding: 100%; */

}

.content video {
  width: 100%;
  display: block;
}

.content:before {
  content: '';
  position: absolute;
  border-radius: 5px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgb(201, 202, 210);
  background: radial-gradient(circle, rgba(201, 202, 210, 1) 0%, rgba(11, 9, 9, 1) 98%);

}

.video1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 320px;
  background: linear-gradient(450deg, black 0%, black 10%, rgba(255, 255, 255, 0) 50%);
  z-index: 999;
  opacity: 0.9;
  left: 1%;
}
@media screen and (max-width:768px){
  .mainContainerpack {
    border:0;
    margin-left: 0;
  }
  #background-video2 {
    width: 100%;
  }
}
