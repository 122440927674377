.without-slider-home-page{
  height: 580px !important;
}
.mainContainer_envision {
    border: 1px solid #fff;
    margin-left: 1%;
    margin-right: 1%;
    font-family: "Honeywell Sans";
    height: 690px;
  }
  .envisionImg{
    width: 100%;
  }
  .heading{
    float: left;
    padding: 1%;
    font-size: calc(100% + 24px);
    font-weight: bold;
    color: #f00;
  }
.body {
/*     background-image: url('../../Assets/envisionYourImpactBkg.jpg');
 */    background:linear-gradient(to left, rgba(0,0,0,0) 20%, rgba(0,0,0,1)), url('../../../../assets/image/envisionYourImpactBkg.jpg');
    padding-top: 15px;
    padding-left: 30px;
    padding-right: 30px;
    color: #fff;
    background-size: cover;
    top: 0;
    height: auto;
    bottom: 0;
  }
  .body p{
    padding-bottom: 0rem !important;
  }
/*   select option{
    background: white;

  }
  select option[value="1"]{
    background: white;
  } */
  select{
    background-color:#ffffff;
    height: 10%;
    color: grey;
   opacity:1
  }
  h1{
    line-height: 0.8;
  }
  .calculate{
    background-color: rgb(5, 122, 193);
    color: #fff;
    border: none;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 45px;
    padding-right: 45px;
    margin-top: 25px;
    font-size: 12px !important;
  }
  
@media only screen and (max-width: 820px){
  #root .without-slider-home-page{
    margin-bottom: 20px;
  } 
.mainContainer_envision{
  height: auto !important;

    }
  }
  @media (min-width: 1920px) and (max-width: 2560px)  {
    .scale::after {
      content: '150%';
    }
    .mainContainer_envision {
      padding-bottom: 20px;
    }
    .mainContainer_envision .midtxt{
      max-width: 23% !important;
      line-height: 1 !important;
    }
    .mainContainer_envision .body{
      padding-left: 10px !important;
      padding-right: 0px !important;
    }
    .mainContainer_envision select{
      background-color:#ffffff;
      height: 10%;
      color: grey;
      opacity:1;
      padding-top:10px !important; 
      padding-bottom:10px !important
    }
    .mainContainer_envision h1{
      font-size: 50px !important;
    }
    .mainContainer_envision .midtxt a{
      font-size:   20px !important;
      
    }
    .mainContainer_envision .col-5{
      line-height: 1 !important;
    }

    .mainContainer_envision p{
      font-size:   18px !important;
      letter-spacing: 0px !important;
      margin-top: 0px !important;
    }
    .mainContainer_envision h3{
      font-size: 20px !important;
    }  
  }
  @media only screen and (max-width: 576px)  {
    .mainContainer_envision {
      padding-bottom: 20px;
    }
    .mainContainer_envision .midtxt{
    line-height: 1 !important;
    font-size: 16px !important;
    padding-top: 0.5vh !important;
    }
    .mainContainer_envision .body{
      padding-left: 5px !important;
      padding-right: 0px !important;
    }
    .mainContainer_envision .calculate{
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 45px;
      padding-right: 45px;
     margin-top: 20px;
      font-size: 12px;
    }
    .mainContainer_envision select{
      background-color:#ffffff;
      height: 10%;
      color: grey;
      opacity:1;
      padding-top:0px !important; 
      padding-bottom:0px !important
    }
    .mainContainer_envision h1{
      font-size: 25px !important;
    }
    .mainContainer_envision .midtxt a{
      font-size: 14px !important;
      
    }
    .mainContainer_envision .col-5{
      line-height: 0.8 !important;
    }
    .mainContainer_envision .screen1gif{
      height: 45px !important;
    }
    .mainContainer_envision p{
      letter-spacing: 0px !important;
    margin-top: 0px !important;
    font-size: 10px !important;
    }
    .calForm{
      max-width: 100% !important;
      margin-right: 10px !important;
      padding: 1% 1% 20px !important;
      margin-top: -10px !important;
    }
    .mainContainer_envision h3{
      font-size: 20px !important;
    }
    .mainContainer_envision span{
      font-size: 10px !important;
    }
  }

@media screen and (max-width: 768px)  {
  .mainContainer_envision {
    margin-left: 0;
    margin-right: 0;
  }
  .mainContainer_envision {
    margin-left: 0;
    margin-right: 0;
  }
}