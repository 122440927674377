.mainContainer5 {
    border: 1px solid #fff;
    margin-left: 1%;
    margin-right: 1%;
    font-family: "Honeywell Sans";top: 0;bottom: 0;
  
  }
  /* Style the video: 100% width and height to cover the entire window */
  #myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
  
  /* Add some content at the bottom of the video/page */
 
  .co2emission{
    z-index: 999;
    position: relative;
    bottom: 0;
    top: 0;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 20px;    color: white;
    width: 100%;
    padding-bottom: 1px;
    font-weight: 500;
  }
  
  /* Style the button used to pause/play the video */
  #myBtn {
    width: 200px;
    font-size: 18px;
    padding: 10px;
    border: none;
    background: #000;
    color: #fff;
    cursor: pointer;
  }
  
  #myBtn:hover {
    background: #ddd;
    color: #0000;
  }
  #background-video5{
    width: 98%;
    height: 355px;
    position: fixed;
    object-fit: cover;
    filter: brightness(40%);
  }

  .mainHeading{
    font-family: "Sans serif";
    font-weight: 700;
    letter-spacing: -2px;
    font-size: 55px;
  }
  .table>:not(caption)>*>* {
    padding: 0rem 0rem 0rem 0rem !important;
    background-color: var(--bs-table-g);
    border-bottom-width: 0px;
}
.co2emission-heading h1{
  font-weight: 500;
  line-height: 0.9;
  font-size: 2.5vw !important;

}

.right-side-image-co2emission{
  text-align: center;
  padding-top: 90px;
  /* background: transparent url('../../../../assets/image/Desktop/Shipping\ Container.png') 0% 0% no-repeat padding-box; */
  opacity: 1;
/*   width: 400px; 
 */}
.right-side-image-co2emission .image{
  width: 100%;
  height: 75%;
}

.co2emission .gif-image svg{
  fill: #fff;
  width: 468px;
  height: 215px;
 }
 .co2emission .gif-image{
  padding-top: 100px !important;
 }
 .co2emission-heading>h2{
 margin-bottom: 12px;
 }
 .co2emission-heading>a{
  margin-bottom: 15px;
  display: block;
 }
 @media only screen and (max-width:768px) {

  .video2:before{
    height: 33% !important;
  }
  .mainContainer5 {
    margin-left: 0;
  }
}
@media only screen and (max-width:820px){
  
  .video2:before{
    height: 320px !important;
  }
  #background-video5{
    height: 294px;
  }
}
 @media only screen and (max-width: 576px) and (max-width: 786px)and (max-width: 768px)and (max-width: 1024px) and (max-width:1180px) {
  .mainContainer5{
    padding-bottom: 20px !important;
  
  }
  .co2emission{
    padding-left: 5px !important;
    padding-right: 0px !important;
  }
  .co2emission h1,.co2emission h2{
    font-size: 16px !important;
    font-weight: bold !important; 
  }
  .co2emission h4{
    font-size: 10px !important;
    line-height: 1.5 !important;
  }
  .right-side-image-co2emission{
    padding-top: 115px;
  }
 }
.col-8{
  padding-right: 0 !important;
}
.video2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 58%;
  background: linear-gradient(450deg, #000 0%, #000 10%, rgba(255,255,255,0) 50%);
  z-index: 999;
  opacity: 0.9;
  left: 1%;
}
@media screen and (max-width:768px){
  #background-video5{
    width: 100%;
  }
}