.splide__pagination--ttb {
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: auto;
    padding: 1em 0;
    right: 2.5em !important;
    top: 0;
}

.splide__pagination__page {
    /* background: white; */
    background: transparent !important;
    border: 1px solid white !important;
    border-radius: 50%;
    display: inline-block;
    height: 8px;
    margin: 3px;
    opacity: .7;
    padding: 0;
    position: relative;
    transition: transform .2s linear;
    width: 8px;
    opacity: 1;
}

.splide__pagination__page.is-active {
    background: #fff !important;
    transform: scale(1.4);
    z-index: 1;
}


.text-theme-red-color {
    color: #DC202E;
}

.splide__track {
    height: 516px !important;
}

@media only screen and (max-width: 786px) {
    .splide__track {
        height: 1100px !important;
    }
}

@media only screen and (max-width: 1200px) {
    .splide__track {
        height: 580px !important;
    }
}

@media only screen and (max-width: 499px) {
    .splide__track {
        height: 1350px !important;
    }
}

@media only screen and (max-width: 768px) {
    .splide__track {
        height: 720px !important;
    }

}

/* @media only screen and (min-width:820px){
    .splide__track{
        height: 1000px !important;
    }
} */

.splide__pagination {
    margin-top: 90px !important;
    justify-content: flex-start !important
}

@media screen and (max-width: 873px) {
    .mktoFormRow .mktoFieldDescriptor {
        width: 145px !important;
        margin: 0 !important;
    }
}

@media screen and (max-width:375px) {
    .splide__pagination {
        visibility: hidden;
    }
}

@media screen and (max-width:414px) {
    .splide__pagination {
        visibility: hidden;
    }
}

@media screen and (min-width: 1700px) {
    .splide__track {
        height: 570px !important;
    }

    .main5 {
        min-height: 450px;
    }

    .co2emission {
        min-height: 420px;
    }

    #background-video-cardboard,
    #background-video-cardboard .video:before {
        height: 500px !important;
    }

    #background-video5,
    #background-video5 .video:before {
        height: 420px !important;
    }

    #background-video1,
    #background-video1 .video:before {
        height: 400px !important;
    }

    #background-video2 {
        height: 380px !important;
    }
}

@property --distance {
    syntax: "<number>";
    initial-value: 0;
    inherits: false;
}

.button-play {
    position: relative !important;
    z-index: 999;
    height: 26px;
    width: 26px;
    margin-left: auto;
    margin-right: 34px;
    top: -16rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eb2b7500;
    border-radius: 50rem;
    border: 2px solid white;
    transition: all 0.2s;
}

.button-play.active {
    padding-left: 6px;
    animation: play-button 5s linear forwards;
}

.button-play.active::before,
.button-play.active::after {
    animation: play-after 0.5s 1 ease forwards;
}

.button-play::before {
    content: "";
    width: 0;
    height: 10px;
    border-top: 7px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 4px solid #fff;
}

.button-play::after {
    content: "";
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 4px solid #fff;
}

@keyframes play-after {
    100% {
        height: 10px;
        background-color: #fff;
        margin: 0 2px;
    }
}