.mainContainerShip {
  border: 1px solid #fff;
  margin-left: 1%;
  margin-right: 1%;
  font-family: "Honeywell Sans";
  background-color: #fff;
}

/* Style the video: 100% width and height to cover the entire window */
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

/* Add some content at the bottom of the video/page */
.shipping-main {
  position: relative;
  bottom: 0;
  padding-top: 15px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 0px;
  color: #fff;
  width: 100%;
  z-index: 999;
}

/* .gif-image{ */
/* padding-bottom: 250px; */
/* } */

.gif-image svg {
  fill: #fff;
  width: 360px;
  height: 270px;
}

.right-side-image-shipping {
  text-align: center;
  padding-top: 80px;
  /* background: transparent url('../../../../assets/image/Desktop/Shipping\ Container.png') 0% 0% no-repeat padding-box; */
  opacity: 1;
}

.right-side-image-shipping .image {
  width: 75%;
  height: 190px;
  padding-left: 20px;
  z-index: 999;
}

@media only screen and (max-width: 576px) and (max-width: 786px)and (max-width: 768px)and (max-width: 1024px) and (max-width:1180px) {
  .mainContainerShip {
    padding-bottom: 20px !important;
    margin-left: 0% !important;
    margin-right: 0% !important;
  }

  .image {
    width: 100px !important;
    height: 120px !important;
    padding-left: 0px !important;
    padding-bottom: 20% !important;
  }

  .shipping-main {
    
    padding-left: 5px !important;
    padding-right: 0px !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
  }

  .shipping-main h1,.shipping-main h2 {
    font-size: 16px !important;
    font-weight: bold !important;
  }

  .shipping-main h4 {
    font-size: 10px !important;
    line-height: 1.5 !important;
    padding-top: 20px !important;
  }

  .gif-image svg {
    fill: #000;
    width: 164px;

    height: 136px;
  }

  .gif-image {
    padding-top: 400px !important;
  }

  .right-side-image-shipping {
    padding-top: 85px;
  }

}

/* Style the button used to pause/play the video */
#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: #000;
}

#background-video1 {
  width: 98%;
  height: 360px;
  position: fixed;
  object-fit: cover;
}

.video:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 390px;
  background: linear-gradient(450deg, #000 0%, #000 10%, rgba(255, 255, 255, 0) 50%);
  z-index: 999;
  opacity: 0.9;
  left: 1%;
}
.eliminate-shipping h2,.eliminate-shipping h1 {
  line-height: .9;
  font-size: 2.5rem;
}
.eliminate-shipping h4 {
  margin: 16px 0 10px;
}
@media only screen and (max-width: 768px){
  #background-video1 {
    width: 100%;
  }
  .mainContainerShip {
    margin-left: 0;
    margin-right: 0;
  }
}