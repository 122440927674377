.mainContainer6 {
  border: 1px solid #fff;
  margin-left: 1%;
  margin-right: 1%;
  font-family: "Honeywell Sans";
}

/* Style the video: 100% width and height to cover the entire window */
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}


/* Style the button used to pause/play the video */
#myBtn {
  width: 200px;
  font-size: 18px;
  padding: 10px;
  border: none;
  background: #000;
  color: #fff;
  cursor: pointer;
}

#myBtn:hover {
  background: #ddd;
  color: black;
}

#background-video-cardboard {
  width: 98%;
  height: 400px;
  position: fixed;
  object-fit: cover;
  filter: brightness(40%);
}

.main5 {
  top: 0;
  position: relative;
  bottom: 0;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 15px;
  color: #fff;
  width: 100%;
  z-index: 999;

}

.cardboard-heading h1 {
  text-align: left;
  font-size: 2.5vw;
  letter-spacing: 0px;
  color: #fff;
  line-height: 1;
}
.cardboard-heading h2 {
  line-height: 1;
}
.cardboard-heading small {
  display: block;
  padding: 10px 0 15px;
}
.cardboard-heading h1:first-child {
  padding-top: 14px;
}

.table thead {
  font-weight: lighter;
}

.dat {
  padding-left: 12px;
  padding-right: 10px;
  height: 90px !important;
}

.right-side-image-cardboard {
  text-align: center;
  padding-top: 60px;
  /* background: transparent url('../../../../assets/image/Desktop/Shipping\ Container.png') 0% 0% no-repeat padding-box; */
  opacity: 1;
}

.right-side-image-cardboard .image {
  width: 230px;
  height: 230px;
  padding-left: 0px;
  padding-bottom: 25px;
}

@media only screen and (max-width: 576px) and (max-width: 786px)and (max-width: 768px)and (max-width: 1024px) and (max-width:1180px) {
  .mainContainer6 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .main5 {
    padding-left: 5px !important;
    padding-right: 0px !important;
    padding-top: 0px !important;

  }

  .main5 h1,.main5 h2 {
    font-size: 15px !important;
    font-weight: bold !important;
    line-height: 1 !important;
  }

  .main5 h4 {
    font-size: 10px !important;
    line-height: 1.5 !important;
  }

  .main5 small {
    font-size: 9px !important;
  }

  .right-side-image-cardboard {
    padding-top: 125px !important;
  }
}

.video3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 350px;
  background: linear-gradient(450deg, #000 0%, #000 10%, rgba(255, 255, 255, 0) 50%);
  z-index: 999;
  opacity: 0.9;
  left: 1%;
}

.card-sml {
  font-size: 10px;
  padding-top: 5px;
}

.arrow {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: none;
}

.arrow span {
  display: block;
  width: 20px;
  height: 20px;
  border-bottom: 5px solid #fff;
  border-right: 5px solid #fff;
  transform: rotate(45deg);
  margin: -10px;
  animation: animate 2s infinite;
}

.arrow span:nth-child(2) {
  animation-delay: -0.2s;
}

.arrow span:nth-child(3) {
  animation-delay: -0.4s;
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}

.circle-arrow {
  position: relative;
  width: 36px;
  height: 36px;
  border: 2px solid #000;
  border-radius: 34px;
  left: 45%;
  top: -20px;
  text-align: center;
  z-index: 999;
  padding-top: 4px;
  display: none;
}

.mainContainer_envision .circle-arrow,
.slide1-container .circle-arrow {
  border: 2px solid #fff;
}

.circle-arrow>img {
  width: 20px;
}

.is-active .circle-arrow {
  display: block;
}

.mainContainer6 .arrow-top {
  top: -78px
}

.slide1-container .circle-arrow {
  top: -12px
}

.mainContainer_envision .circle-arrow {
  top: -58px
}

@media screen and (max-width:768px) {

  #background-video-cardboard {
    height: 320px !important;
  }

  /*  .video3:before{
    height: 45% !important;
  } */
  .right-side-image-cardboard .image {
    width: 240px;
    height: 240px;
    padding-bottom: 10%;
  }

  .mainContainer6 {
    margin-left: 0;
  }

  #background-video-cardboard {
    width: 100%;
  }

  .arrow {
    top: 78%;
    z-index: 9999;
  }

  .arrow span {
    border-bottom: 5px solid #f00;
    border-right: 5px solid #f00;
  }

  .mainContainer_envision .circle-arrow {
    top: -50px;
    left: 60%;
  }

  .mainContainer6 .arrow-top {
    top: -40px;
  }
}

@media screen and (max-width:300px) {
  .mainContainer_envision .circle-arrow {
    left: 70%;
  }
  .slide1-container .circle-arrow {
    top: -388px;
  }
  .mainContainer6 .arrow-top {
    top: -40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 900px) {
  .mainContainer_envision .circle-arrow {
    left: 42%;
  }
}
@media only screen and (min-width: 1600px)  {
  .mainContainer6 .arrow-top {
      top: -110px;
  }
}
