.result-table-statistics .data-result-output div span {
    display: block;
}

.result-table-statistics .dat {
    padding-left: 12px;
    padding-right: 10px;
    height: 90px !important;
}

.result-table-statistics{
    
    padding-left: 11px;
    padding-right: 9.5px;
    /* padding-top:490px; */
    position: relative;
    border: none;
  
}

.result-table-statistics .data-result-output{
    background-color: #e5e5e5;
    /* height: 30px; */
}

.result-table-statistics .data-result-output div{
    padding-bottom: 0px;
    height: 30px;
}
@media only screen and (max-width: 820px) {
    .result-table-statistics .data-result-output div{
        height: auto;
    }
}
@media only screen and (max-width: 912px) {
    .result-table-statistics .data-result-output div{
        height: auto;
    }
}

.result-table-statistics .result-table-header{
    background-color: #9c9c9c;
}



@media only screen and (max-width: 786px) {
    .result-table-statistics {
        padding-left: 11px !important;
        padding-right: 2px !important;
    }
    .result-table-header .col-3 {
        font-size: 12px !important;
        height:auto !important;
        padding-bottom: 1%;
    }
    .result-table-header .col-2 {
        font-size: 12px !important;
        height:auto !important;
        padding-bottom: 1%;
    }
    .result-table-header .col-1 {
        font-size: 12px !important;
        height:auto !important;
        padding-bottom: 1%;
    }

    .screen-2 .data-result-output .name .l1{
        padding-top: 10px !important;
    }
    .screen-2 .data-result-output .ann{
        padding-left: 5px !important; 
        font-size: 12px !important;
        padding-top: 0px !important;
        
    }
    .screen-2 .data-result-output .col-2{
        padding-left: 5px !important; 
        font-size: 12px !important;
        padding-top: 0px !important;
    }
     .screen-2 .data-result-output .col-2 .l2{
        padding-top: 15px !important;
    }
    .screen-2 .data-result-output .ann .l2{
        padding-top: 15px !important;
    }
    .screen-2 .data-result-output .col-1{
        visibility: hidden;
    }
    .screen-3 .data-result-output .name{
        padding-left: 25px !important; 
        padding-right: 30px !important;
        font-size: 12px !important;
        padding-top: 0px !important;
    }
    .screen-3 .data-result-output .name .l1{
        padding-top: 10px !important;
    }
    .screen-3  .data-result-output .ann{
        padding-left: 5px !important; 
        font-size: 12px !important;
        padding-top: 0px !important;
        
    }
    .screen-3 .data-result-output .col-2{
        padding-left: 5px !important; 
        font-size: 12px !important;
        padding-top: 0px !important;
    }
    .screen-3 .data-result-output .col-2 .l2{
        padding-top: 10px !important;
    }
    .screen-3 .data-result-output .ann .l2{
        padding-top: 10px !important;
    }
    .screen-3 .data-result-output .col-1{
        visibility: hidden;
    }
    


















    .data-result-output .name{
        padding-left: 25px !important; 
        padding-right: 30px !important;
        font-size: 12px !important;
        padding-top: 0px !important;
    }
    .data-result-output .name .l1{
        padding-top: 10px !important;
    }
    .data-result-output .ann{
        padding-left: 5px !important; 
        font-size: 12px !important;
        padding-top: 0px !important;
        
    }
    .data-result-output .col-2{
        padding-left: 5px !important; 
        font-size: 12px !important;
        padding-top: 0px !important;
    }
    .data-result-output .col-2 .l2{
        padding-top: 15px !important;
    }
    .data-result-output .ann .l2{
        padding-top: 15px !important;
    }
    .data-result-output .col-1{
        visibility: hidden;
    }
}

.data-result-output .equal{
    padding-left: 0 !important;
}