.footer-content__container,.footer-content__bottom{
    margin: auto 0px !important;
}

#myAeroFooterDvNew .aem-Grid .footer .footer-content{
    margin-top: 0px !important;
}

#root #myAeroNavMnuDvNew .defaultNavigation .gdm-header-navigation{
    display: none !important;
}