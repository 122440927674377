.bck {
    background-image: url(./Pill-header@2x.png);
    background-size: 53%;
    border: 1px solid white;
    font-family: "Honeywell Sans";
    line-height: 1.2;
    margin-left: 1%;
    margin-right: 1%;
    padding: 2% 2% 1%;
    margin-top: 0px;
    height: 372px;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-position: right center;
    background-color: #cccccc80;
}

@media only screen and (max-width: 768px) {
    .bck {
        height: 450px !important;
        background-position: 100%;
        background-size: cover;
    }
    .result-table-statistics .data-result-output div {
        height: auto;
    }
}

.bck h1 {
    font: normal normal 900 60px/60px Honeywell Sans;
}

.bck .button {
    width: 40%;
    height: 45px;
    background-color: #057AC1;
    color: white;
    border: none;
    height: 40px;
    margin-left: 2%;
}

.result-email-form form {
    padding-right: 5%;
}

.result-email-form input {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 0.6rem;
}

.result-email-form select {
    padding: 0.6rem !important;
    height: auto;
}

.result-email-form .mktoLogicalField input {
    margin: 10px;
    height: 14px;
}

.result-email-form .mktoLogicalField label {
    font-size: 13px;
}

.result-email-form .mktoLogicalField label p {
    font-family: "Honeywell Sans";
    font-weight: 800;
}

.result-email-form .mktoLogicalField label a {
    color: #000000
}

.result-email-form .mktoLogicalField {
    display: flex;
}


.result-email-form form {
    margin-left: 0px;
}

.result-email-form form .mktoFieldWrap.mktoRequiredField label {
    display: none;
}

.result-email-form form .mktoFieldWrap.mktoRequiredField input {
    -webkit-appearance: none;
    appearance: none;
    height: 40px !important;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    color: #212529;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

.result-email-form form .mktoFieldWrap.mktoRequiredField select {
    -webkit-appearance: none;
    appearance: none;
    height: 40px !important;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    color: #212529;
    display: block;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 0.375rem 0.75rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
}

.success-form-class {
    color: #000000;
    font-family: 'Honeywell Sans';
    font-weight: 800;
    padding-top: 90px;
    padding-right: 6%;
}

.result-email-form>form>.mktoFormRow:nth-of-type(9) {
    width: 100%;
    font-family: 'Honeywell Sans' !important;
}

.result-email-form>form>.mktoFormRow:nth-of-type(8) {
    width: 90.5%;
}

.result-email-form form .mktoFormRow {
    margin-left: 0px !important;
}

.result-email-form form {
    margin-left: 0px !important;
}
.imagine h1{
  font-weight: bold;
  color: #DC202E;
}
.imagine h1>span{
    color: #000;
    display: block;
}
.imagine p{
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2;
    width: 70%;
}
@media only screen and (min-width: 900px) and (max-width: 1400px){
    .imagine p{      
        width: 812px;
    }
}

@media only screen and (max-width: 576px) and (max-width: 786px)and (max-width: 768px)and (max-width: 1024px) and (max-width:1180px) {
    .bck h1 {
        font-size: 25px !important;       
    }

    .bck a,
    .bck span,
    .bck p {
        font-size: 10px !important;
        line-height: 1.4 !important;
    }

    .result-table-statistics .data-result-output div {
        height: auto;
    }

    .mktoFormRow {
        padding: 0 !important;
        width: 100% !important;

        margin-right: 0px !important;
        margin-left: 0px !important;
    }
}

.result-email-form p {
    margin-bottom: 2px !important;
}


.result-email-form .mktoCheckboxList strong {
    font-size: 10px;
}

.result-email-form .mktoFieldWrap label {
    font-size: 12px;
    padding: 0px 0 !important;
}

.result-email-form .mktoForm .mktoButtonRow .mktoButtonWrap .mktoButton {
    margin-top: 0px !important;
    padding: 10px 15px !important;

}

@media only screen and (max-width: 820px) {
    .result-email-form p {
        font-size: 9px !important;

    }

    .result-email-form span {
        font-size: 12px !important;
        font-weight: bold !important;
    }

    .bck a {
        font-size: 12px !important;
        padding: 0 !important;
    }

}

@media screen and (max-width: 873px) {
    .bck .mktoForm .mktoFormRow {

        margin-left: 0px !important;
    }
}

@media screen and (max-width:820px) {
    .bck p {
        font-size: 12px !important;
        width: 100% !important;
    }

    .result-email-form form .mktoFieldWrap.mktoRequiredField input {
        height: 25px !important;

    }

    .result-email-form form .mktoFieldWrap.mktoRequiredField select {
        height: 25px !important;
        padding-top: 1px !important;
        padding-bottom: 1px !important;
    }

    .result-email-form .mktoCheckboxList strong {
        padding: 0 !important;
    }
}

@media screen and (max-width:320px) {
    .circle-arrow {
        left: 39%;
        top: -13px;
    }
}
@media screen and (min-width:2350px) {
    #background-video2 {
        height: 460px !important;
    }
    #background-video5, #background-video5 .video:before {
        height: 500px !important;
    }
    .splide__track {
        height: 620px !important;
    }
}
@media screen and (min-width:3080px) {
    #background-video2 {
        height: 500px !important;
    }
    #background-video5, #background-video5 .video:before {
        height: 560px !important;
    }
    .splide__track {
        height: 700px !important;
    }
    .mainContainer6 .arrow-top {
        top: -167px;
    }
}