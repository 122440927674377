#myAeroNavMnuDvNew
  .global-header
  .global-header-container
  .logo-container
  .tagline {
  padding-top: 15px;
}
#myAeroNavMnuDvNew .global-header .global-header-container .nav-container .primary-navigation li {
  font-size: 18px;
  text-align: left;
  padding: 8px 20px !important;
  >a{
    padding: 0;
  }
  .go-to-subnav:before{
    display: inline;
  }
}

@media (max-width: 767px){
  .splide__list .splide__slide{   
  >div{
    margin-bottom: 20px;
  }
}
  #myAeroNavMnuDvNew .global-header .global-header-container .nav-container .primary-navigation li {
    font-size: 18px;
    text-align: left;
    padding: 8px 20px !important;
    >a{
      padding: 0;
      display: inline;
    }
    .go-to-subnav:before{
      position: relative;
      top: 3px;
      display: inline;
    }
    span.signin-label {    
      padding: 0;
      max-width: 200px;
  }
  &.mobile-close{
    text-align: right;
  }
  button.icon-close{
    width: 30px;
  }
  }
}
#myAeroNavMnuDvNew .global-header .global-header-container .nav-container .primary-navigation ul li span.icon {
  font-family: icomoonregular !important;
  outline: 0;
  background: #d28c8c00;
}
