
.mainMore{
  top: 0;
  bottom: 0;
}
.slide1-container {
  background: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .3)), url(./Grass-header@2x.png);
  padding-top: 10px;
  background-size: cover;
  border: 1px solid white;
  margin-left: 1%;
  margin-right: 1%;
  font-family: "Honeywell Sans";
  margin-top: 10px;
  bottom: -30px;
  padding-left: 10%;
  padding-right: 10%;
  background-position: bottom;
  height: 506px;
}

.tab{
  border-left: 20px solid rgba(243, 243, 243, 0.82) !important;
  border-right: 20px solid rgb(220, 220, 220) !important;
  color: black;
}
.h5{
  font-size: 14px;
}

th:last-child,
td:last-child {
  background-color: rgba(0, 0, 0, 0.10196078431372549);
  padding: 10px !important;
}
thead tr th .head{
  padding: 10px !important;
}

@media only screen and (max-width: 1200px) {
  .slide1-container {
    padding: 0px 3%;
    height: 560px !important;
  }
}

@media only screen and (max-width: 768px) {
  .slide1-container{
    height: 690px !important;
    
  }
  .slide1-container h1{
    font-size: 20px !important;
    margin-bottom: 0 !important;
    margin-top: 5px;
  }
  .slide1-container thead{
    font-size: 12px !important;
  }
  .table>:not(caption)>*>* {
    padding: 0px 0rem 0px 0rem !important;
  }
  td{
    font-size: 10px !important;
  }
  .slide1-container p {
    font-size: 10px !important;
}

  th:last-child,
td:last-child {
  background-color: rgba(0, 0, 0, 0.10196078431372549);
  padding: 0px !important;
}
  .slide1-container .tab{
    border-left: 20px solid rgba(243, 243, 243, 0.82) !important;
    border-right: 20px solid rgb(220, 220, 220) !important
  }
  .slide1-container {
    background: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .3)), url(./Grass-header@2x.png);  
    background-position: bottom !important;  
  }
  .slide1-container .h5{
    font-size: 75%;
  }
  .slide1-container h4{
    font-size: 75%;
  }
  .slide1-container p{
    font-size: 80%;
  }

  
  td:last-child {
    font-size: 90%;
  }
  .slide1-container {
    margin-left: 0;
    margin-right: 0;
  }
}

@media only screen and (max-width: 499px) {
  .slide1-container {
    /* background-position: top !important; */
  }
  .cmp-text{
    font-size: 75%;
  }
}

@media only screen and (max-width: 399px) {

  td:last-child {
    font-size: 75%;
  }
}

.table>:not(caption)>*>* {
  padding: 10px 0rem 10px 0rem ;
}