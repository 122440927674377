 
.mktoModal .mktoModalClose {
    display: none;
}
.mktoForm .mktoButtonRow{
    text-align: center !important;
}
.mktoForm .mktoFormRow,.mktoForm .mktoFormCol{
    width: 98% !important;
}
 